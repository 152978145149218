header.chat-header .media .media-body {
  display: block;
}

header.chat-header .media .media-body .user-name,
header.chat-header .media .media-body .user-status {
  max-width: 100% !important;
}

.chat-body .media.saved + .media:not(.saved) {
  border-top: 1px dashed #eaeaea;
  padding-top: 1.25rem;
}

.stsearch-box input {
  padding: 2px 5px;
  border: none;
  outline: none;
}
